import "./App.css";
import Counter from "./Counter"; // Assuming you've placed the Counter component in a separate file
import mytext from "./mytextfile.js";
import DataDisplay from "./DataDisplay.js";
const App = () => {
  return (
    <div className={"counter"}>
      {/* <h1>Counter App</h1>*/}

      {/* <DataDisplay />*/}
      <Counter />
    </div>
  );
};

export default App;
