import React, { useState, useEffect } from "react";
import DataDisplay from "./DataDisplay.js";

const Counter = () => {
  const deathEvery = "79429";
  const sss = getTimeElapsedSince9AM();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(sss);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://russianwarship.rip/api/v2/statistics/latest")
      .then((response) => response.json())
      .then((result) => {
        setData(result.data.stats.personnel_units);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
      setLoading(!loading);
    }, deathEvery);

    const xxx = () => {
      // console.log(loading);
    };
    return () => {
      clearInterval(interval);
      xxx();
    };
  });

  function getTimeElapsedSince9AM() {
    const now = new Date();
    const nineAM = new Date(now);
    nineAM.setHours(9, 0, 0, 0);

    const elapsedMilliseconds = now - nineAM;
    if (elapsedMilliseconds >= 0) {
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
      const deadSince = Math.floor((elapsedSeconds / deathEvery) * 1000);
      return deadSince;
    } else {
      const deadSince2 = Math.floor(
        elapsedMilliseconds / deathEvery + (86400 / deathEvery) * 1000
      );
      return deadSince2;
    }
  }

  const BackCounter = ({ start }) => {
    const [count, setCount] = useState(start);

    useEffect(() => {
      if (count > 0) {
        const timer = setTimeout(() => setCount(count - 1), 1000);
        return () => clearTimeout(timer); // Cleanup the timeout on unmount
      }
    }, [count]);

    return <span>Наступна мразота здохне через: {count} секунд.</span>;
  };

  return (
    <div>
      <div className="wrapper">
        <h1>Перший лічильник дохлої русні в реальному часі</h1>
        <h2>
          Здохло москалів за сьогодні<span className="ho">*</span>:
        </h2>
        <div className="numbers" key={counter}>
          {counter}
        </div>
        <h2>a всього </h2>
        <div className="numbers">{counter + data}</div>

        <div className="sample-texts">
          <div className="sample-text">Як це працює?</div>
          <div className="para">
            Наш лічильник має дуже просту логіку роботи: ми можемо відносно
            точно сказати скільки, плюс-мінус, йобаних москалів здохне у муках у
            певний день, бо маємо статистику попередніх місяців. З неї ми можемо
            вирахувати середнє значення і порахувати, як швидко дохнуть ці
            тварі. На момент написання цього тексту москаль в Україні дохне зі
            швидкістю 1 йобана русня на {deathEvery / 1000} секунд, або,
            приблизно, три чверті москалика на хвилину.
          </div>
          <div className="sample-text">Навіщо?</div>
          <div className="para">
            Бо це, бляха, весело. Навіщо чекати цілий день до нових даних
            Генштабу, коли можна порадіти наживо? А щоб вам було приємніше разом
            зі мною радіти кожному дохлому москалю, я додав феєрверки. Якщо ви
            затримаєтесь на нашому сайті на півтори хвилинки, то побачите всю
            магію знову! <BackCounter start={Math.floor(deathEvery / 1000)} />{" "}
            Уіііііі!
          </div>
          <div className="sample-text">Що мені з цим робити тепер?</div>
          <div className="para">
            Якщо вас це радує так само, як і мене — покажіть друзям, поширюйте
            радість. А також можете кинути мені копійочку{" "}
            <a href="https://send.monobank.ua/jar/8TvHcq8gzk">на банку</a>. Всі
            мільйони, зароблені на даному сайті, підуть на купівлю запчастин для
            FPV дронів.
          </div>
          <div className="para">
            * - дані рахуються з моменту їх анонсу о 9 ранку кожного дня, тому
            лічильник починає рахувати з цього часу.
          </div>
          <div className="para">
            пошта для пропозицій і вашої любові:&nbsp;
            <a href="mailto: hello@dohlimoskali.online">
              hello@dohlimoskali.online
            </a>
          </div>
          <div>
            подяка за api:
            <a href="http://russianwarship.rip"> russianwarship.rip</a>
          </div>
        </div>

        <div>
          <div className={loading ? "firework" : "firework2"}></div>
          <div className={loading ? "firework" : "firework2"}></div>
          <div className={loading ? "firework" : "firework2"}></div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
