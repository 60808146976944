import React, { useState, useEffect } from "react";

function getTimeElapsedSince9AM() {
  const now = new Date();
  const nineAM = new Date(now);
  nineAM.setHours(9, 0, 0, 0); // Set to 9:00 AM today

  const elapsedMilliseconds = now - nineAM;
  const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
  const deadSince = Math.floor(elapsedSeconds / 89);
  return deadSince;
}

const sss = getTimeElapsedSince9AM();

const DataDisplay = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://russianwarship.rip/api/v2/statistics/latest")
      .then((response) => response.json())
      .then((result) => {
        setData(result.data.stats.personnel_units);
        setLoading(false);
        console.log(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>Posts from API:</h2>
      <div>{sss}</div>
      <div>{data}</div>
    </div>
  );
};

export default DataDisplay;
